import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'pageList',
    //redirect: '/'
    component: () => import('../views/report/index.vue')
  },
  {
    path: '/complaint',
    name: 'Complaint',
    component: () => import('../views/report/info.vue')
  },
  {
    path: '/yhk-info',
    name: 'yhkInfo',
    component: () => import('../views/report/yhk-info.vue')
  },
  {
    path: '/pay-order',
    name: 'payOrder',
    component: () => import('../views/report/pay.vue')
  },
  {
    path: '/pay-success',
    name: 'paySuccess',
    //redirect: '/'
    component: () => import('../views/report/pay-success.vue')
  },
  {
    path: '/pay-error',
    name: 'payError',
    //redirect: '/'
    component: () => import('../views/report/pay-success.vue')
  },
  // {
  //   path: '/',
  //   name: 'report',
  //   component: () => import('../views/report/report2.vue')
  // },
  {
    path: '/report/search',
    name: 'reportSearch',
    component: () => import('../views/report/report-search.vue')
  },
  {
    path: '/report/accurate',
    name: 'reportAccurate',
    component: () => import('../views/report/report-accurate.vue')
  },
  {
    path: '/report/info',
    name: 'reportInfo',
    component: () => import('../views/report/report-pay.vue')
  },
  {
    path: '/submit/result',
    name: 'submitResult',
    component: () => import('../views/report/report-pay-result.vue')
  },
  {
    path: '/sso-login',
    name: 'ssoLogin',
    component: () => import('../views/report-process-search/sso-login.vue')
  },
  {
    path: '/processing-progress',
    name: 'processingProgress',
    component: () => import('../views/report-process-search/processing-progress.vue')
  },
  {
    path: '/apply-invoice',
    name: 'applyInvoice',
    component: () => import('../views/report-process-search/apply-invoice.vue')
  },
  {
    path: '/service-evaluation',
    name: 'serviceEvaluation',
    component: () => import('../views/report-process-search/service-evaluation.vue')
  },
  {
    path: '/evaluation-success',
    name: 'evaluationSuccess',
    component: () => import('../views/report-process-search/public-result.vue')
  },
  {
    path: '/submit-success',
    name: 'submitSuccess',
    component: () => import('../views/report-process-search/public-result.vue')
  },
  // {
  //   path: '/pay-success',
  //   name: 'submitSuccess',
  //   component: () => import('../views/report/public-result.vue')
  // },
  {
    path: '/:jobId',
    name: 'whitePageJob',
    component: () => import('../views/white-page-job.vue'),
  },
  {
    path: '/invalid-link',
    name: 'invalidLink',
    component: () => import('../views/report-process-search/public-result.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router